import { useState } from 'react';

export const useOrder = () => {
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(null);
    const [orderSuccess, setOrderSucess] = useState(null);

    const generateNextOrder = async (curID) => {
        const response = await fetch('https://api.twistmedia.online/orderLog/new', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                orderID: curID+1,
                result1: 0,
                result2: 0,
                result3: 0,
                totalResult: 0
            })
        })
        const json = await response.json();

        if (!response.ok) {
            setError(json.error);
        }
        if (response.ok) {
            // setIsLoading(false);
            // setTimeout(() => window.location.reload(), 2000);
            // window.location.reload();
            window.location.href = "/";
        }
    }

    const placeOrder = async (orderID, authToken, curUserId, userCashBal, username, userStatus, item1, item2, item3, item4, unitPrice, finalAmount) => {
        setIsLoading(true);
        setOrderSucess(false);
        setError(null);

        if (userCashBal < unitPrice) {
            setError("Insufficient cash balance.");
            setIsLoading(false);
            return;
        }
        const response = await fetch('https://api.twistmedia.online/order/create', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`
            },
            body: JSON.stringify({
                orderID: orderID,
                username,
                item1,
                item2,
                item3,
                item4,
                price: unitPrice,
                totalAmount: finalAmount,
                status: "Matching",
                remarks: "",
                matchBy:""
            })
        })
        const json = await response.json();

        if (!response.ok) {
            setIsLoading(false);
            setError(json.error);
        }
        if (response.ok) {
            if (userStatus === "Incomplete Task") {
                userStatus = "Disabled";
            }
            const response = await fetch('https://api.twistmedia.online/user/' + curUserId, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`
                },
                body: JSON.stringify({cashWallet: userCashBal-unitPrice, status: userStatus})
            })
            const updateBal = await response.json();
            if (!response.ok) {
                setIsLoading(false);
                setError(updateBal.error);
            }
            if (response.ok) {
                setIsLoading(false);
                setOrderSucess(true);
                // window.location.href = "/orders";
            }
        }
        
    }

    return { placeOrder, generateNextOrder, isLoading, error, orderSuccess };
}