import { createContext, useReducer } from 'react';

export const PropertyContext = createContext();

export const propertiesReducer = (state, action) => {
	switch (action.type) {
		case 'GET_PROPERTIES':
			return {
				properties: action.payload
			};
		case 'ADD_PROPERTY':
			return {
				properties: [action.payload, ...state.properties]
			};
		case 'DELETE_PROPERTY':
			return {
				properties: state.properties.filter((u) => u._id !== action.payload._id)
			};
		default:
			return state;
	}
}

export const PropertyContextProvider = ({children}) => {

	const [state, dispatch] = useReducer(propertiesReducer, {
		properties: null
	});
	return (
		<PropertyContext.Provider value={{...state, dispatch}}>
			{ children }
		</PropertyContext.Provider>
	)
}