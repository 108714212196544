// Dependencies
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Sidebar, Menu, MenuItem, SubMenu, menuClasses } from 'react-pro-sidebar';
import { NavLink } from 'react-router-dom';

// Hooks
import { useLogout } from './hooks/useLogout';
import { useAuthContext } from './hooks/useAuthContext';

// Pages
import Home from "./pages/home";
import StarScore from "./pages/star-score";
import Ordering from "./pages/ordering";
import Profile from "./pages/profile";
import Orders from "./pages/orders";
import Withdraw from "./pages/withdraw";
import Login from "./pages/login";
import Signup from "./pages/signup";

import Logotext from './assets/img/logo.png';

function App() {

  const { user } = useAuthContext();
  const [toggled, setToggled] = useState(false);
	const [userData, setUserData] = useState();

  const getUserData = async () => {
    const response = await fetch('https://api.twistmedia.online/user/'+ user.username.toLowerCase(), {
      headers: {
        'Authorization': `Bearer ${user.token}`
      }
    });
    const json = await response.json();
    if (response.ok) {
			if (json.length === 1) {
				// Expect only 1 result
				setUserData(json[0]);
        if (json[0].freezed) {
          localStorage.removeItem('chabevent_logged_in');
          window.location.href= "/";
        }
			} else {
				console.log("Invalid user data: More than 1 result.");
			}
    }
  }

  const { logout } = useLogout();
  const handleLogout = () => {
    logout();
    window.location.href= "/";
  }

  useEffect(() => {
		if (user) {
			getUserData();
		}
	}, []);

  return (
    <Router>
      {user == null ? (
        <Routes>
          <Route path="/" element={<Login/>}/>
          <Route path="/signup" element={<Signup/>}/>
        </Routes>
      ):(
        <>
          {user.freezed ? (
            <>
              <section className="top-menu">
                <div className="container form-page-padding">
                  <div className="row">
                    <div className="col-12">
                      <img className="mobile-header-logo my-3" src={Logotext}></img>
                    </div>
                  </div>
                </div>
              </section>

              <div className="container form-page-padding" style={{marginTop: "80px"}}>
                <div className="row">
                  <div className="col-12">
                    <div className="error-box py-3">
                      <h6 className="error mb-0">Account Suspended</h6>
                      <p className="mb-0">This account has violated our Terms & Conditions.</p>
                    </div>
                    <button className="btn action-button remove-btn mt-2" onClick={(e) => {
                      handleLogout();
                    }}>
                      <p className="mb-0">Logout<i className="fa-solid fa-right-from-bracket ml-2"></i></p>
                    </button>
                  </div>
                </div>
              </div>

            </>
          ):(
            <>
              
              <section className="top-menu">
                <div className="container form-page-padding">
                  <div className="row">
                    <div className="col-9">
                      <img className="mobile-header-logo my-3" src={Logotext}></img>
                    </div>
                    <div className="col-3 text-right">
                      <button className="btn px-0 mt-2" onClick={(e) => {
                        getUserData();
                        setToggled(!toggled);
                      }}>
                        <span className="top-menu-icon text-gray">
                          <i className="fa-sharp fa-light fa-bars"></i>
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </section>

              <div className="d-flex">
                <Sidebar 
                  transitionDuration={500}
                  width="260px"
                  backgroundColor="#000"
                  breakPoint="all"
                  onBackdropClick={() => setToggled(false)}
                  toggled={toggled}
                  rootStyles={{
                    height: '100vh',
                    zIndex: '110',
                    border: 'none'
                  }}
                >

                  <NavLink to="/">
                    <div className="side-menu-brand-area">
                        <img className="side-menu-logotext" src={Logotext} alt="logo"></img>
                    </div>
                  </NavLink>

                  <hr className="sidebar-line"></hr>

                  <h6 className="text-gray m-3">Welcome, <span className="blue">{user.username.toLowerCase()}</span></h6>

                  <div className="sidebar-data-box d-flex justify-contain-left mx-3 mb-3 py-2">
                    <div className="data-icon-box bg-dark-green">
                      <span className="data-box-icon text-gray">
                        <i className="fa-solid fa-wallet"></i>
                      </span>
                    </div>
                    <div className="data-text-box">
                      <p className="mb-0"><strong>Cash Wallet</strong></p>
                      {userData ? (
                        <p className="data-box-number profit-green mb-0">{parseFloat(userData.cashWallet).toFixed(2)}</p>
                      ):(
                        <p className="data-box-number profit-green mb-0">0.00</p>
                      )}

                    </div>
                  </div>

                  <Menu
                    renderExpandIcon={({ open }) => 
                      <span className="collapse-icon">
                        {open ? <i className="fa-duotone fa-caret-down mt-2"></i> : <i className="fa-duotone fa-caret-right mt-2"></i>}
                      </span>
                    }
                    rootStyles={{
                      ['.' + menuClasses.button]: {
                        backgroundColor: '#000',
                        color: '#DCDCDC',
                        '&:hover': {
                          backgroundColor: '#e98922',
                        },
                      },
                      ['.active']: {
                        backgroundColor: '#e98922',
                      }
                    }}
                  >
                    <MenuItem 
                      icon={<span className="side-menu-icon"><i className="fa-solid fa-house"></i></span>}
                      component={<NavLink to="/" activeClassName={['menu-item'].active}/>}
                    > 
                      <p className="side-menu-text">Home</p>
                    </MenuItem>

                    <MenuItem 
                      icon={<span className="side-menu-icon"><i className="fa-solid fa-money-bill-transfer"></i></span>}
                      component={<NavLink to="/withdraw" activeClassName={['menu-item'].active}/>}
                    > 
                      <p className="side-menu-text">Withdraw</p>
                    </MenuItem>

                    <MenuItem 
                      icon={<span className="side-menu-icon"><i className="fa-regular fa-clipboard-list"></i></span>}
                      component={<NavLink to="/ordering/66a23fb21a08d32c028edb20" activeClassName={['menu-item'].active}/>}
                    > 
                      <p className="side-menu-text">Ordering</p>
                    </MenuItem>

                    <MenuItem 
                      icon={<span className="side-menu-icon"><i className="fa-solid fa-list-check"></i></span>}
                      component={<NavLink to="/orders" activeClassName={['menu-item'].active}/>}
                    > 
                      <p className="side-menu-text">Order Record</p>
                    </MenuItem>

                    <SubMenu
                      icon={<span className="side-menu-icon"><i className="fa-solid fa-user"></i></span>}
                      label={<p className="side-menu-text">Profile</p>}
                    >
                      <MenuItem 
                        icon={<span className="side-menu-icon"><i className="fa-regular fa-user-pen"></i></span>}
                        component={<NavLink to="/profile" activeClassName={['menu-item'].active}/>}
                      > 
                        <p className="side-menu-text">Personal Details</p>
                      </MenuItem>
                      <MenuItem 
                        icon={<span className="side-menu-icon"><i className="fa-solid fa-stars"></i></span>}
                        component={<NavLink to="/star-score" activeClassName={['menu-item'].active}/>}
                      > 
                        <p className="side-menu-text">Star Point</p>
                      </MenuItem>
                    </SubMenu>

                    <MenuItem
                      className="menu-item"
                      icon={<span className="side-menu-icon"><i className="fa-solid fa-right-from-bracket"></i></span>}
                      onClick={handleLogout}
                    > 
                      <p className="side-menu-text">Logout</p>
                    </MenuItem>
                  </Menu>

                </Sidebar>

                <main className="main-content">

                  <Routes>
                    <Route path="/" element={<Home/>}/>
                    <Route path="/star-score" element={<StarScore/>}/>
                    <Route path="/ordering/:productID" element={<Ordering/>}/>
                    <Route path="/profile" element={<Profile/>}/>
                    <Route path="/orders" element={<Orders/>}/>
                    <Route path="/withdraw" element={<Withdraw/>}/>
                  </Routes>
                </main>
              </div>
            </>
          )}

        </>
      )}
      
    </Router>
  );
}

export default App;