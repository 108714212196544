import { useState } from 'react';

export const useWithdrawal = () => {
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(null);


    const submitWithdraw = async (authToken, curUserId, withdrawCount, curUsername, withdrawAmount, userBank, accountNumber, accountName, curCashBal, activeBank) => {
        setIsLoading(true);
        setError(null);

        if (curCashBal < withdrawAmount) {
            setError("Insufficient cash balance.");
            setIsLoading(false);
            return;
        }
        if (userBank !== '' && accountNumber !== '' && accountName !== '') {
            const response = await fetch('https://api.twistmedia.online/withdrawal/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`
                },
                body: JSON.stringify({
                    withdrawID: Number(withdrawCount)+1,
                    username: curUsername,
                    amount: withdrawAmount,
                    bankName: userBank,
                    accNumber: accountNumber,
                    accName: accountName,
                    status: "Processing",
                    walletBefore: curCashBal,
                    walletAfter: curCashBal-withdrawAmount,
                    processBy: "",
                    remarks: ""
                })
            })
            const json = await response.json();
    
            if (!response.ok) {
                setIsLoading(false);
                setError(json.error);
            }
            if (response.ok) {
                if (!activeBank) {
                    const response = await fetch('https://api.twistmedia.online/user/' + curUserId, {
                        method: 'PATCH',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${authToken}`
                        },
                        body: JSON.stringify({
                            bankName: userBank,
                            accNumber: accountNumber,
                            accName: accountName
                        })
                    })
                    const json = await response.json();
            
                    if (!response.ok) {
                        console.log("Update bank details failed.");
                    }
                    if (response.ok) {
                        console.log("Bank details updated.");
                    }
                }
                const response = await fetch('https://api.twistmedia.online/user/' + curUserId, {
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${authToken}`
                    },
                    body: JSON.stringify({cashWallet: curCashBal-withdrawAmount})
                })
                const updateBal = await response.json();
                if (!response.ok) {
                    setIsLoading(false);
                    setError(updateBal.error);
                }
                if (response.ok) {
                    setIsLoading(false);
                }
            }
        }
    }

    return { submitWithdraw, isLoading, error };
}