import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

// Hooks
import { useAuthContext } from '../hooks/useAuthContext';

// Images
import HomeBanner from "../assets/img/banner/home-banner.jpg";


const Home = () => {
	
	const { user } = useAuthContext();
	const [userdata, setUserData] = useState([]);
	const [productsData, setProductsData] = useState();

	useEffect(() => {


		const getUserData = async () => {
			const response = await fetch('https://api.twistmedia.online/user/'+ user.username.toLowerCase(), {
				headers: {
					'Authorization': `Bearer ${user.token}`
				}
			});
			const json = await response.json();

			if (response.ok) {
				if (json.length === 1) {
					setUserData(json[0]);
					if (json[0].freezed) {
						localStorage.removeItem('chabevent_logged_in');
						window.location.href= "/";
					}
				} else {
					console.log("Invalid user data: More than 1 result.")
				}
			}
		}

		const getProductsData = async () => {
			const response = await fetch('https://api.twistmedia.online/product/active', {
				headers: {
					'Authorization': `Bearer ${user.token}`
				}
			});
			const json = await response.json();
			if (response.ok) {
				setProductsData(json);
			}
		}

		if (user) {
			getUserData();
			getProductsData();
		}

	}, []);

  	return (
		<>	
			
			{userdata != null ? (
				<div className="container form-page-padding">
					<div className="row mt-3">
						<div className="col-12 px-2 mt-2">
							<div className="page-container">
								<div className="row">
									<div className="col-4 text-center px-0">
										<Link to="/profile">
											<button className="btn py-0 w-100">
												<span className="profile-menu-icon">
													<i className="fa-solid fa-user"></i>
												</span>
												<p className="profile-menu-text mb-0">Profile</p>
											</button>
										</Link>
									</div>
									<div className="col-4 text-center px-0">
										<Link to="/withdraw">
											<button className="btn py-0 w-100">
												<span className="profile-menu-icon">
													<i className="fa-solid fa-money-bill-transfer"></i>
												</span>
												<p className="profile-menu-text mb-0">Withdraw</p>
											</button>
										</Link>
									</div>
									<div className="col-4 text-center px-0">
										<Link to="/orders">
											<button className="btn py-0 w-100">
												<span className="profile-menu-icon">
													<i className="fa-regular fa-clipboard-list"></i>
												</span>
												<p className="profile-menu-text mb-0">Orders</p>
											</button>
										</Link>
									</div>
								</div>
							</div>
						</div>
					</div>
			
					<div className="row mt-3">
						<div className="col-12 mt-3">
							<h3 className="mb-0">Purchase Orders</h3>
						</div>
					</div>
					<div className="row mb-5 px-2">
						{productsData && productsData.map((product, index) => (
							<>
								<div className="col-4 col-sm-3 px-2 mt-3" key={index}>
									<Link to={'/ordering/' + product._id}>
										<img className="product-cover" src={product.coverImgLink} alt="ProductCover"></img>
									</Link>
								</div>
							</>
						))}
					</div>

				</div>
			):(
				<></>
			)}
		</>
	);

};

export default Home;