import { useState } from 'react';

export const useSignup = () => {
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(null);

    const signup = async (username, fullname, email, phone, password) => {
        setIsLoading(true);
        setError(null);

        const response = await fetch('https://api.twistmedia.online/user/signup', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
			body: JSON.stringify({
                username,
                fullname,
                referrer: "master",
                email,
                phone,
                password,
                securityPassword: '',
                rank: "Member",
                cashWallet: 0,
                points: 500,
                matchVolume: 0,
                bankName: '',
                accNumber: '', 
                accName: '',
                status: "New",
                freezed: false
            })
        })
        const json = await response.json();

        if (!response.ok) {
            setIsLoading(false);
			setError(json.error);
		}
		if (response.ok) {
            setIsLoading(false);
            window.location.href = "/";
		}
    }

    return { signup, isLoading, error };
}